<template>
  <div class="layoutDefault" style="margin-top: 2rem">
    <div class="grid grid-cols-1 gap-4">
      <div class="z-10">
        <div class="flex cursor-pointer rounded-lg py-2" @click="$router.go(-1)">
          <IconArrow
            class="mr-3 mt-1 stroke-current text-habu h-6 w-6 stroke-2 transform rotate-0"
          />
          <span class="text-2xl text-normal">User Profile</span>
        </div>
      </div>

      <div class="grid-cols-1 gap-5" :class="edit ? 'md:flex' : ''">
        <div :class="edit ? 'sm:col-span-1' : 'sm col-span-3'">
          <div class="hcard relative z-10 p-7">
            <div
              class="xs:grid-cols-1 xs:gap-5"
              :class="edit ? 'grid-cols-1 gap-5' : 'sm:flex sm:justify-between'"
            >
              <div class="flex-initial">
                <div class="text-32px font-semibold text-black">User</div>
                <div class="flex mt-7">
                  <IconProfile class="mr-2 h-5.4 w-5.4 mt-1" />
                  <span class="text-2xl font-semibold">{{ user.name }}</span>
                </div>
                <div class="flex mt-4">
                  <IconPosition class="mr-2 h-5.4 w-5.4" /> <span>{{ user.client.position }}</span>
                </div>
                <div class="flex mt-4">
                  <IconEmail class="mr-2 h-5.4 w-5.4" /> <span>{{ user.email }}</span>
                </div>
                <div class="flex mt-4">
                  <IconPhone class="mr-2 h-5.4 w-5.4" /> <span>{{ user.phone_number }}</span>
                </div>
              </div>

              <div class="flex-initial" :class="edit ? 'pb-16' : ''">
                <button
                  class="btn btn-warning hover:bg-hwarning text-white capitalize h-8.1 w-22 border-0"
                  @click="toAction('edit')"
                  :style="edit ? 'float: right' : ''"
                  :class="edit ? 'xs:mt-5' : 'mt-5 sm:mt-0'"
                >
                  <IconEdit class="mr-2 h-4.16 w-5" /> Edit
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="sm:mt-5 md:mt-0 flex-auto" v-if="edit && menu == 'edit'">
          <div class="hcard relative z-10 p-7" v-if="user.client">
            <div class="flex">
              <IconEdit class="mr-3 h-9 w-10.5" />
              <span class="text-4xl font-semibold">Edit User</span>
            </div>
            <div class="relative mt-7">
              <label class="label pl-0 pb-1 text-sm font-medium"> Name </label>
              <input
                class="input input-bordered pl-10 rounded-lg w-full py-2.5 pr-3 h-9.3 text-sm"
                id="name"
                type="text"
                placeholder="Your name"
                v-model="form.name"
                disabled
              />
              <div class="absolute left-2.2 bottom-2.2 flex items-center">
                <IconProfile class="h-4.3 w-4.3" />
              </div>
            </div>

            <div class="relative mt-3">
              <label class="label pl-0 pb-1 text-sm font-medium"> Position </label>
              <input
                class="input input-bordered pl-10 rounded-lg w-full py-2.5 pr-3 h-9.3 text-sm"
                id="position"
                type="text"
                placeholder="Your Position"
                v-model="form.position"
                disabled
              />
              <div class="absolute left-2.2 bottom-2.2 flex items-center">
                <IconPosition class="h-4.3 w-4.3" />
              </div>
            </div>

            <div class="relative mt-3">
              <label class="label pl-0 pb-1 text-sm font-medium"> Phone </label>
              <input
                class="input input-bordered pl-10 rounded-lg w-full py-2.5 pr-3 h-9.3 text-sm"
                id="phone"
                type="number"
                placeholder="Your Phone"
                v-model="form.phone_number"
              />
              <div class="absolute left-2.2 bottom-2.2 flex items-center">
                <IconPhone class="h-4.3 w-4.3" />
              </div>
            </div>

            <div class="relative mt-3">
              <label class="label pl-0 pb-1 text-sm font-medium"> Email </label>
              <input
                class="input input-bordered pl-10 rounded-lg w-full py-2.5 pr-3 h-9.3 text-sm"
                id="email"
                type="text"
                placeholder="Your Email"
                disabled
                v-model="user.email"
              />
              <div class="absolute left-2.2 bottom-2.2 flex items-center">
                <IconEmail class="h-4.3 w-4.3" />
              </div>
            </div>

            <div class="relative mt-3">
              <label class="label pl-0 pb-1 text-sm font-medium"> Current Password </label>
              <input
                class="input input-bordered pl-10 rounded-lg w-full py-2.5 pr-3 h-9.3 text-sm"
                id="current_phone"
                type="password"
                placeholder="Your Current Password"
                v-model="form.current_password"
              />
              <div class="absolute left-2.2 bottom-2.2 flex items-center">
                <IconPassword class="h-4.3 w-4.3" />
              </div>
            </div>
            <div class="relative mt-3">
              <label class="label pl-0 pb-1 text-sm font-medium">
                <span>New Password</span>
                <div class="inline-flex whitespace-nowrap" v-if="v$.form.password.$error">
                  <img :src="IconWarning" class="mr-2" />
                  <span
                    class="text-red-500 italic"
                    style="font-size: 14px; line-height: 1; font-weight: 300"
                  >
                    Please enter valid password!
                  </span>
                </div>
              </label>
              <input
                class="input input-bordered pl-10 rounded-lg w-full py-2.5 pr-3 h-9.3 text-sm"
                id="password"
                type="password"
                placeholder="Your New Password"
                v-model="form.password"
                :disabled="
                  !form.current_password ||
                  (form.current_password && form.current_password.lenght < 7)
                "
              />
              <div class="absolute left-2.2 bottom-2.2 flex items-center">
                <IconPassword class="h-4.3 w-4.3" />
              </div>
            </div>
            <div class="relative mt-3">
              <label class="label pl-0 pb-1 text-sm font-medium">
                <span>Confirm Password</span>
                <div
                  class="inline-flex whitespace-nowrap"
                  v-if="v$.form.password_confirmation.$error"
                >
                  <img :src="IconWarning" class="mr-2" />
                  <span
                    class="text-red-500 italic"
                    style="font-size: 14px; line-height: 1; font-weight: 300"
                  >
                    Please enter same as your new password!
                  </span>
                </div>
              </label>
              <input
                class="input input-bordered pl-10 rounded-lg w-full py-2.5 pr-3 h-9.3 text-sm"
                id="password_confirm"
                type="password"
                placeholder="Confirm New Password"
                v-model="form.password_confirmation"
                :disabled="!form.password"
              />
              <div class="absolute left-2.2 bottom-2.2 flex items-center">
                <IconPasswordConfirmation class="h-4.3 w-4.3" />
              </div>
            </div>

            <div class="flex justify-end mt-8">
              <button
                class="btn btn-primary hover:bg-hblue h-9.3 w-24 capitalize border-0"
                @click="submit"
              >
                Save
              </button>
            </div>

            <div class="mt-7 py-4 px-9 bg-hblue2 rounded-21px">
              <div class="font-medium">Note:</div>
              <ul class="list-disc">
                <li class="font-medium text-sm">Alamat email digunakan pada saat login</li>
                <li class="font-medium text-sm">
                  Password dapat direset oleh staft kami. silahkan menghubungin salah satu daftar
                  Support kami yang pada menu Profile
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="sm:mt-5 md:mt-0 flex-auto" v-if="edit && menu == 'switch'">
          <div class="hcard relative z-10 p-7" v-if="user.client">
            <div class="flex">
              <IconEdit class="mr-3 h-9 w-10.5" />
              <span class="text-4xl font-semibold">Switch Customer</span>
            </div>

            <div class="grid grid-cols-1 mt-9">
              <div class="hcard p-6 mb-6" v-for="sc in switchs" :key="sc.id">
                <div class="flex space-x-5">
                  <img :src="IconHospital" class="flex-initial" />
                  <div>
                    <div class="text-lg font-semibold text-hblue1">
                      {{ sc.name }}
                    </div>
                    <div class="text-sm text-hblue1">
                      {{ sc.area ? sc.area.area : '-' }}
                    </div>
                    <div class="text-sm">
                      {{ sc.address }}
                    </div>
                  </div>
                </div>
                <div class="flex justify-end mt-4">
                  <button
                    class="btn btn-outline hover:bg-hblue capitalize hover:border-transparent h-8.1 w-28 rounded-full"
                    @click="toSwitch(sc)"
                    :disabled="loading"
                    :class="loading == true ? 'loading' : ''"
                  >
                    Select
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="grid-cols-1 gap-5" v-if="!edit">
        <div class="hcard relative z-10 p-7">
          <div class="sm:flex sm:justify-between grid-cols-1 gap-5">
            <div class="flex-initial" v-if="user.customer && user.customer.data">
              <div class="text-32px font-semibold text-black">Customer</div>
              <div class="flex mt-7">
                <IconCustomer class="mr-2 h-5.4 w-5.4 mt-1" />
                <span class="text-2xl font-semibold">{{ user.customer.data.name }}</span>
              </div>
              <div class="flex mt-4">
                <IconAddress class="mr-2 h-5.4 w-5.4" />
                <span>{{ user.customer.data.address }}</span>
              </div>
              <div class="flex mt-4">
                <IconPhone class="mr-2 h-5.4 w-5.4" />
                <span>{{ user.customer.data.phone_number }}</span>
              </div>
            </div>
            <div class="flex-initial mt-5 sm:mt-0">
              <button
                class="btn btn-outline capitalize h-8.1 w-28"
                @click="toAction('switch')"
                :style="edit ? 'float: right' : ''"
              >
                Switch
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconArrow from '@/assets/icons/icon-arrow.svg?inline'
import IconEdit from '@/assets/icons/icon-edit.svg?inline'
import IconEmail from '@/assets/icons/icon-email.svg?inline'
import IconPhone from '@/assets/icons/icon-phone.svg?inline'
import IconProfile from '@/assets/icons/icon-profile.svg?inline'
import IconAddress from '@/assets/icons/icon-address.svg?inline'
import IconCustomer from '@/assets/icons/icon-customer.svg?inline'
import IconPosition from '@/assets/icons/icon-position.svg?inline'
import IconPassword from '@/assets/icons/icon-password.svg?inline'
import IconPasswordConfirmation from '@/assets/icons/icon-passwordConfirmation.svg?inline'

import IconWarning from '@/assets/icons/icon-warning.svg'
import IconHospital from '@/assets/icons/icon-hospitalBg.svg'

import { mapState, mapActions } from 'vuex'
import useVuelidate from '@vuelidate/core'
import { required, requiredIf, minLength, sameAs } from '@vuelidate/validators'

export default {
  name: 'profile',
  components: {
    IconArrow,
    IconEdit,
    IconEmail,
    IconPhone,
    IconProfile,
    IconAddress,
    IconCustomer,
    IconPosition,
    IconPassword,
    IconPasswordConfirmation
  },
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      IconWarning,
      IconHospital,
      edit: false,
      menu: null,
      loading: false,
      form: {
        name: '',
        position: '',
        phone_number: '',
        current_password: '',
        password: '',
        password_confirmation: ''
      }
    }
  },
  validations() {
    return {
      form: {
        phone_number: {
          required
        },
        password: {
          required: requiredIf(function () {
            return this.form.current_password ? true : false
          }),
          minLength: minLength(8)
        },
        password_confirmation: {
          required: requiredIf(function () {
            return this.form.password ? true : false
          }),
          sameAsPassword: sameAs(this.form.password)
        }
      }
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
      switchs: state => state.auth.switchs
    })
  },
  watch: {
    user: {
      deep: true,
      immediate: true,
      handler(val) {
        this.form.name = val.name
        this.form.phone_number = val.phone_number
        this.form.position = val.client ? val.client.position : '-'
      }
    }
  },
  mounted() {
    this.GET_SWITCH_CUSTOMERS({
      offset: 0,
      limit: 10
    })
  },
  methods: {
    ...mapActions('auth', ['EDIT_USER', 'GET_SWITCH_CUSTOMERS', 'SWITCH_CUSTOMER', 'ME']),
    async submit() {
      const result = await this.v$.$validate()
      if (!result) {
        return
      }

      let res = await this.EDIT_USER(this.form)

      if (res) {
        this.$toast.info('Success update profile.')

        this.edit = false
      }
    },
    toAction(val) {
      this.menu = val
      this.edit = !this.edit
    },
    async toSwitch(val) {
      this.loading = true

      let res = await this.SWITCH_CUSTOMER(val.id)

      if (res) {
        this.$toast.info('Success switch customer.')
      }
      await this.ME()

      this.edit = false
      this.loading = false
    }
  }
}
</script>
